import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import "./Search.css";
import Context from "../../Context/DogContext";
import { BsSearch, BsHeart, BsHeartFill } from "react-icons/bs";
import { BiHeart } from "react-icons/bi";

export const Search = ({dataDog}) => {
  const breedsArray = [];
  const [data, setData] = useState([]);
  const [selectedBreed, setSelectedBreed] = useState("affenpinscher");
  const [breedImages, setBreedImages] = useState([]);
  const [value, setValue] = useState('')
  const {addDog, removeDog} = useContext(Context)

  useEffect(() => {
    axios
      .get("https://dog.ceo/api/breeds/list/all")
      .then((response) => setData(response.data.message));
  }, []);

  function createBreedsList(data) {
    Object.keys(data).map((breed) => {
      return breedsArray.push(breed.charAt(0).toUpperCase() + breed.substr(1));
    });
  }
  createBreedsList(data);

  useEffect(() => {
    axios
      .get(`https://dog.ceo/api/breed/${selectedBreed}/images`)
      .then((response) => setBreedImages(response.data.message));
  }, [selectedBreed]);

  const arrayBreedImages = breedImages.slice(0, 10);

  const handleInput = ({ target }) => {
    setValue(target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSelectedBreed(value.charAt(0).toLowerCase()+value.slice(1))
  };


  return (
    <>
      <form className="buscador"
            onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Affenpinscher"
          className="input w-full max-w-xs pl-2 bgInput mb-10"
          onChange={handleInput}
        />
        <button className="btn mb-10" type="submit">
          <BsSearch className="mr-5" />
          Buscar
        </button>
      </form>
      <div className="cardContainer w-full">
        {arrayBreedImages.map((image) => { 
          return (
            <div className="card m-10 ">
              <img
              className="image w-full h-full	object-contain rounded-lg"
              key={arrayBreedImages.indexOf(image)}
              src={image}
              alt={selectedBreed}
            />
            {
              addDog ? (
              <button className="btnFav" onClick={()=>addDog(image)}><BiHeart  /></button>) :(
              <button className="btnFav" onClick={()=>removeDog(image)}><BsHeartFill /></button>)
            }
            
            </div>

          );
        })}
      </div>
    </>
  );
};
