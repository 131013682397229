import React, { createContext, useState } from 'react'



const Context =createContext();

export const DogContextProvider = ({children}) => {
    const [fav, setFav] = useState([])

    const favArray = []
    const isInFav = (id) => {
        return fav.some(el => el.id === id)
      }
    const addDog = (dogToAdd) => {
        const newObj = {
            dogToAdd,
          }
      if(isInFav(newObj.id)){
      fav.map(el => {
        if(el.id === newObj.id)  {
            removeDog(newObj.id)
            localStorage.clear();

        }
        return(el)
        })

    }else {
        setFav([...fav, newObj])
        favArray.push([...fav, newObj])
        console.log(favArray)
        localStorage.setItem('dogs', JSON.stringify([...fav, newObj]))
      
    }
  }
  const removeDog = (id) => {
    const deleteItem = fav.filter(el => el.id !== id)
    setFav([...deleteItem]);
  }
  const clear = () => {
    setFav([])
  }

  return (
    <Context.Provider value={{
      fav,
      addDog,
      removeDog,
      clear,
    }}> 
      {children}
    </Context.Provider>
  )
}

export default Context



