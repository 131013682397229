import React, { useState, useEffect } from 'react'
import { Search } from '../Search/Search'




export const DogContainer = () => {
    const [dog, setDog] = useState([])
    

    const dogs = async() => {
        const data = await fetch('https://dog.ceo/api/breeds/list/all')
        const dogsInfo = await data.json()
        const dogsArray = []
        dogsArray.push(Object.entries(dogsInfo.message))
        setDog(dogsArray[0])
    }
    
    // console.log(dog)
    useEffect(()=>{
        setTimeout(()=>{
            dogs()
        },2000)
    }, [])
    

    return (
    <>
        <div>
            <Search dataDog={dog}  />
        </div>
    </>
    )
}
