import './App.css';
import { NavBar } from './Components/NavBar/NavBar';
import { DogContainer } from './Components/DogContainer/DogContainer';
import { Favourites } from './Components/Favourites/Favourites';
import { DogContextProvider } from './Context/DogContext';


const App = () => 
  <>  
  <DogContextProvider>
    <div className="App">
      <NavBar />
      <DogContainer />
      <Favourites />
    </div>
  </DogContextProvider>

</>


export default App;
